// src/services/api.js
// import axios from 'axios';

// Create an instance of axios
// const api = axios.create({
//   baseURL: 'http://localhost:3000/api',
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// Function to handle POST requests
export const postData = async (endpoint, data) => {
  console.log("data", data);
  return;
  // try {
  //   const response = await api.post(endpoint, data);
  //   return response.data;
  // } catch (error) {
  //   console.error('Error in API call:', error);
  //   throw error;
  // }
};
